/*
 * Pie Chart
 * Draw a pie chart according to the dimensions of the Paper.
 * Parameters set in the options object:
 * - cx: x coordenate of the center of the pie
 * - cy: y coordenate of the center of the pie
 * - r: radius of the pie
 * - values: Array of values
 * - labels: Array of labes asigned to the values
 * - total (optional): Sum of all the values
 * - legend (optional): Boolean value to show/hide a legend of the values. Default value: false
 * - stroke (optional): Hex color of the lines stroke. Default value: '#FFFFFF'
 * - listeners: object containing functions to be invoked on several events. Allowed attributes are:
 *      - click: click on a sector
 *      - dblclick: double click on a sector
 */
Raphael.fn.pieChart = function(options) {
    var paper = this,
        rad = Math.PI / 180,
        chart = this.set(),
        legend = this.set(),
        initAngle = 0,
        animationMs = 150,
        animationEasing = 'easeOut',
        titleHeight = 0,
        titleMargin = 0,
        pieWidth = 0,
        pieHeight = 0,
        percentLabelSize = 11,
        percentLabelHeight,
        percentLabelWidth,
        percentLabelPosition,
        legendWidth = 0,
        legendHeight = 0,
        legendXPos = 0,
        legendYPos = 0,
        legendLabelMaxWidth = 0,
        legendValueMaxWidth = 0,
        legendPercentMaxWidth = 0,
        legendTitleSize = 11,
        legendTitleHeight = 0,
        legendIconSize = 8,
        legendIconMargin = 5,
        legendLabelMarginPercent = 3,
        legendLabelMargin,
        legendRowMargin,
        legendRowMarginPercent = 1,
        totalNumSize = 29,
        totalLabelSize = 14,
        tooltip = null,
        cx, cy, r, r2,
        width = isNaN(this.width) ? this._viewBox[2] : this.width,
        height = isNaN(this.height) ? this._viewBox[3] : this.height,
        tooltipId = 'svgPieChartTooltip',
        tooltipOffsetX = 30,
        tooltipOffsetY = 0,
        titleBelow = false,
        summaryIconBorder = 0,
        summaryIconIncrement = 2,
        sectorIncrement = 1.03,
        fontSizeRef = 242,
        legendMetrics,
        baseFontFamily = 'Century Gothic, CenturyGothic, AppleGothic, sans-serif',
        piechartYMargin, piechartXMargin,
        noData = false;

    var textRuler = paper.text(-10000, -10000, '').attr( { fill: 'none', stroke: 'none' } );

    function getRealSize(size) {
        return Math.round((size * _.min([width, height])) / fontSizeRef);
    }

    function getTextSize(text, fontSize, fontWeight, fontFamily) {
        textRuler.attr({ text: text, 'font-family': fontFamily || baseFontFamily, 'font-size': getRealSize(fontSize),
            'font-weight': fontWeight});
        var bbox = textRuler.getBBox();
        return {width: bbox.width, height: bbox.height};
    }

    function sector(cx, cy, r, startAngle, endAngle, params) {
        var ret;
        var x1 = cx + r * Math.cos(-startAngle * rad),
            x2 = cx + r * Math.cos(-endAngle * rad),
            y1 = cy + r * Math.sin(-startAngle * rad),
            y2 = cy + r * Math.sin(-endAngle * rad);

        if (startAngle == 0 && endAngle == 360) {
            ret = paper.circle(cx, cy, r);
        } else {
            ret = paper.path([
                'M', cx, cy,
                'L', x1, y1,
                'A', r, r, 0, +(endAngle - startAngle > 180), 0, x2, y2, 'z'
            ]);
        }

        return ret.attr(params);
    }

    function onClick(p, value, id, label) {
        if (options.listeners && options.listeners.click) {
            if (options.listeners.click(p, value, id, label) === false) {
                // avoid selection
                return;
            }
        }

        if (options.selectableSectors) {
            paper.toggleSector(p);
        }
        tooltip.style.display = 'none';
    }

    function onDblClick(p, value, id, label) {
        if (options.listeners && options.listeners.dblclick) {
            options.listeners.dblclick(p, value, id, label);
        }
        tooltip.style.display = 'none';
    }

    function onHover(x, y, label) {
        if (x !== undefined && y !== undefined) {
            tooltip.style.left = (x + tooltipOffsetX) + 'px';
            tooltip.style.top = (y + tooltipOffsetY) + 'px';
            if (label) {
                tooltip.innerHTML = label;
                tooltip.style.display = 'block';
            }
        } else {
            tooltip.style.display = 'none';
        }
    }

    function createSector(data) {
        var pieSector = null;

        if (data.value) {
            pieSector = sector(cx, cy, r, data.startAngle, data.endAngle,
                    {fill: data.color, stroke: options.stroke,
                        'stroke-width': options.strokeWidth || 1, cursor: data.cursor});
            var txtXPos = cx + percentLabelPosition * Math.cos(-data.textangle * rad);
            var txtYPos = cy + percentLabelPosition * Math.sin(-data.textangle * rad);
            var txt = data.angleplus > 15 ? paper.text(txtXPos, txtYPos, data.percent + '%').
                attr({
                    'fill': options.sectorPercentLabelColor,
                    'font-size': getRealSize(percentLabelSize),
                    'font-family': baseFontFamily, cursor: data.cursor}) :
                null;

            if (data.clickable) {
                pieSector.click(function() {
                    onClick(pieSector, data.value, data.id, data.label);
                });
            }

            if (data.clickable || data.dblclickable) {
                pieSector.dblclick(function() {
                    onDblClick(pieSector, data.value, data.id, data.label);
                });
            }

            pieSector.hover(function(event, x, y) {
                onHover(x, y, data.tooltip);
            }, function() {
                onHover();
            }).mousemove(function(event, x, y) {
                onHover(x, y);
            });

            chart.push(pieSector);

            if (txt) {
                if (data.clickable) {
                    txt.click(function() {
                        onClick(pieSector, data.value, data.id, data.label);
                    });
                }

                if (data.clickable || data.dblclickable) {
                    txt.dblclick(function() {
                        onDblClick(pieSector, data.value, data.id, data.label);
                    });
                }

                txt.hover(function(event, x, y) {
                    onHover(x, y, data.tooltip);
                }, function() {
                    onHover();
                }).mousemove(function(event, x, y) {
                        onHover(x, y);
                    });

                chart.push(txt);
            }

            data.pieSector = pieSector;
            data.pieLabel = txt;
        }

        return pieSector;
    }

    function addLegend(data) {
        var currentSize;
        var curXPos = legendXPos;
        var legendIcon;
        var labelTxt;
        var valueTxt;
        var percentTxt;

        curXPos += legendValueMaxWidth + legendLabelMargin;

        valueTxt = paper.text(curXPos,
                legendYPos, M2M.lib.Format.genericNumber(data.value, 2, true)).attr({fill: options.legendKeyColor,
                'font-family': baseFontFamily,
                'font-size': getRealSize(options.legendTextSize), 'text-anchor': 'end'});

        legend.push(valueTxt);

        curXPos += legendPercentMaxWidth + legendLabelMargin;

        percentTxt = paper.text(curXPos,
                legendYPos, data.percent).attr({fill: options.legendPercentColor,
                'font-size': getRealSize(options.legendTextSize),
                'font-family': baseFontFamily,
                'text-anchor': 'end'});

        legend.push(percentTxt);

        curXPos += legendLabelMargin;

        if (options.legendSquare) {
            legendIcon = paper.rect(curXPos, legendYPos - legendIconSize,
                legendIconSize * 2, legendIconSize * 2).attr({fill: data.color, stroke: 'none'});
        } else {
            curXPos += legendIconSize;
            legendIcon = paper.circle(curXPos,
                legendYPos, legendIconSize).attr({fill: data.color, stroke: 'none', cursor: data.cursor});
        }

        legend.push(legendIcon);

        curXPos += legendIconSize + legendIconMargin;

        var dif = width - (curXPos + legendLabelMaxWidth + legendLabelMargin);

        if (dif > 0 || !paper.paragraph) {
            labelTxt = paper.text(curXPos,
                legendYPos, data.label).attr({fill: options.legendValueColor,
                    'font-size': getRealSize(options.legendTextSize),
                    'font-family': baseFontFamily,
                    'text-anchor': 'start', cursor: data.cursor});
        } else {
            labelTxt = paper.paragraph({x: curXPos, y: legendYPos, text: data.label,
                maxWidth: legendLabelMaxWidth + dif,
                maxHeight: legendMetrics.height,
                textStyle: {fill: options.legendValueColor,
                    'font-size': getRealSize(options.legendTextSize),
                    'font-family': baseFontFamily,
                    'text-anchor': 'start', cursor: data.cursor}
            });
        }

        legend.push(labelTxt);

        currentSize = labelTxt.getBBox();

        legendYPos += currentSize.height + legendRowMargin;

        if (data.clickable) {
            legendIcon.click(function() {
                onClick(data.pieSector, data.value, data.id, data.label);
            });

            labelTxt.click(function() {
                onClick(data.pieSector, data.value, data.id, data.label);
            });
        }

        if (data.clickable || data.dblclickable) {
            legendIcon.dblclick(function() {
                onDblClick(data.pieSector, data.value, data.id, data.label);
            });

            labelTxt.dblclick(function() {
                onDblClick(data.pieSector, data.value, data.id, data.label);
            });
        }

        legendIcon.hover(function(event, x, y) {
            onHover(x, y, data.tooltip);
        }, function() {
            onHover();
        }).mousemove(function(event, x, y) {
                onHover(x, y);
            });

        labelTxt.hover(function(event, x, y) {
            onHover(x, y, data.tooltip);
        }, function() {
            onHover();
        }).mousemove(function(event, x, y) {
                onHover(x, y);
            });

        if (data.pieSector) {
            data.pieSector.legendIcon = legendIcon;
        }
    }

    function createTooltip() {
        tooltip = tooltip || document.getElementById(tooltipId);
        if (!tooltip) {
            var mainContent = options.tooltipCnt ? document.getElementById(options.tooltipCnt) : document.body;
            if (mainContent) {
                tooltip = document.createElement('div');
                tooltip.setAttribute('class', 'basic-tooltip');
                tooltip.setAttribute('id', tooltipId);
                mainContent.appendChild(tooltip);
            }
        }

        tooltip.style.display = 'none';
    }

    function preCalcValues() {
        var values= [];

        if (options.values && options.values.length) {
            var legendLabelWidth = 0;
            var legendValueWidth = 0;
            var legendPercentWidth = 0;

            for (var i = 0; i < options.values.length; i++) {
                var value = {
                    id: options.ids[i],
                    value: options.values[i] === undefined ? '' : options.values[i],
                    label: options.labels[i],
                    color:  (options.colors && options.colors[options.repeatColors ? i % options.colors.length : i]) ||
                        Raphael.getColor(),
                    index: i
                };

                legendMetrics = getTextSize(value.label, options.legendTextSize);
                legendLabelWidth = legendMetrics.width;

                legendHeight += legendMetrics.height + legendRowMargin;

                if (legendLabelWidth > legendLabelMaxWidth) {
                    legendLabelMaxWidth = legendLabelWidth;
                }

                legendValueWidth = getTextSize(value.value.toLocaleString(), options.legendTextSize).width;

                if (legendValueWidth > legendValueMaxWidth) {
                    legendValueMaxWidth = legendValueWidth;
                }

                value.startAngle = initAngle;
                value.angleplus = options.total ? ((360 * value.value) / options.total) : 0;
                value.textangle = initAngle + (value.angleplus / 2);
                value.percent = options.total ?  M2M.lib.Format.genericNumber(
                    Number(value.value * 100 / options.total),
                    options.percentDecimals || 0,
                    true) : 0;
                value.tooltip = value.label + '<br><b>' + M2M.lib.Format.genericNumber(value.value, 0, false) +
                    ' (' + value.percent + '%)</b>';

                value.clickable = !options.clickDisabled && (options.clickable === undefined || options.clickable[i])
                    && (options.zeroClickable || value.value > 0);

                value.dblclickable = (options.dblclickable === undefined || options.dblclickable[i]) &&
                    (options.zeroClickable || value.value > 0);

                value.cursor = value.clickable || value.dblclickable ? 'pointer' : 'default';

                legendPercentWidth = getTextSize(value.percent, options.legendTextSize).width;

                if (legendPercentWidth > legendPercentMaxWidth) {
                    legendPercentMaxWidth = legendPercentWidth;
                }

                initAngle += value.angleplus;

                value.endAngle = initAngle;

                values.push(value);
            }
        }

        return values;
    }

    function autoCalcPositions(values) {
        var heightOffset = 0;
        var widthOffset = 0;

        piechartYMargin = height * options.piechartMarginPercent / 100;
        piechartXMargin = width * options.piechartMarginPercent / 100;

        var realLegendWidth =  legendLabelMaxWidth + 4 * legendLabelMargin + legendIconSize +
            legendValueMaxWidth + legendIconMargin + (options.legendSquare ? 0 : legendIconSize) +
            legendPercentMaxWidth;

        if (options.legend) {
            if (options.legendSizePercent) {
                legendWidth = width * options.legendSizePercent / 100;
            } else {
                legendWidth = realLegendWidth;
            }
        }

        if (!values || values.length == 0) {
            legendWidth = 0;
            realLegendWidth = 0;
            options.legend = false;
        }

        if (options.title) {
            heightOffset +=  titleHeight + titleMargin;
        }

        var percentLabelMetrics = getTextSize(Number(100).toFixed(options.percentDecimals || 0) + '%', percentLabelSize);

        percentLabelHeight = percentLabelMetrics.height;
        percentLabelWidth = percentLabelMetrics.width;

        legendIconSize = getRealSize(legendIconSize);


        if (options.sectorPercentLabelMargin) {
            heightOffset += 2 * (options.sectorPercentLabelMargin + percentLabelHeight);
            widthOffset  += 2 * (options.sectorPercentLabelMargin + percentLabelWidth);
        }

        if (options.keyName) {
            legendHeight += legendTitleHeight + 2 * legendRowMargin;
        }

        if (options.legendPosition === 'bottom' || options.legendPosition === 'top') {
            heightOffset += legendHeight + legendLabelMargin;
            widthOffset += piechartXMargin * 2;
        } else {
            widthOffset += legendWidth;
            heightOffset += piechartYMargin * 2;
        }

        // set center and radius of the pie
        cx = (width - widthOffset) / 2;
        cy = (height - heightOffset) / 2;
        r = _.min([cx, cy]);

        cx += piechartXMargin;
        cy += piechartYMargin;

        pieHeight = cy + r;
        pieWidth = cx + r;

        r2 = options.donutRatio * r;

        if (options.legend) {
            switch (options.legendPosition) {
                case 'right':
                    cx = pieWidth / 2;
                    legendXPos = width - legendWidth;
                    legendYPos = (height - legendHeight) / 2; // Center legend vertically
                    break;
                case 'left':
                    cx = width - cx;
                    legendYPos = (height - legendHeight) / 2; // Center legend vertically
                    break;
                case 'bottom':
                    cx = width / 2;
                    cy = pieHeight / 2;
                    legendXPos = (width - legendWidth) /2; // Center legend horizontally
                    legendYPos = pieHeight + (height - pieHeight - legendHeight) / 2;
                    break;
                case 'top':
                    cx = width / 2;
                    cy = height - r - legendLabelMargin;
                    legendYPos = (height - legendHeight - pieHeight) / 2;
                    legendXPos = (width - legendWidth) /2; // Center legend horizontally
                    break;
            }
        } else {
            cx = width / 2;
        }

        if (!titleBelow) {
            cy += titleHeight + titleMargin;
        }

        var legendDif = legendWidth - realLegendWidth;

        if (legendDif > 0) {
            legendXPos += legendDif; // Center legend horizontally
        }
    }

    function setManualPositions() {
        cx = width * options.cxPercent / 100;
        cy = height * options.cyPercent / 100;
        r = height * options.radiusPercent / 100;
        r2 = height * options.holePercent / 100;
        legendXPos = width * options.lxPercent / 100;
        legendYPos = height * options.lyPercent / 100;
    }

    function setUp() {
        var values;

        if (options.fontSizeRef) {
            fontSizeRef = options.fontSizeRef;
        }

        if (options.percentLabelSize) {
            percentLabelSize = options.percentLabelSize;
        }

        if (options.legendTitleSize) {
            legendTitleSize = options.legendTitleSize;
        }

        if (options.baseFontFamily) {
            baseFontFamily = options.baseFontFamily;
        }

        if (options.totalNumSize) {
            totalNumSize = options.totalNumSize;
        }

        if (options.totalLabelSize) {
            totalLabelSize = options.totalLabelSize;
        }

        if (options.titleBelow) {
            titleBelow = options.titleBelow;
        }

        if (options.legendIconSize) {
            legendIconSize = options.legendIconSize;
        }

        if (options.legendLabelMarginPercent) {
            legendLabelMarginPercent = options.legendLabelMarginPercent;
        }

        if (options.legendRowMarginPercent) {
            legendRowMarginPercent = options.legendRowMarginPercent;
        }

        if (options.keyName) {
            legendTitleHeight = getTextSize('M', legendTitleSize).height;
        }

        if (options.title) {
            titleHeight = getTextSize('M', 15, 'bold').height;
            titleMargin = 10;
        }

        legendLabelMargin = width * legendLabelMarginPercent / 100;
        legendRowMargin = height * legendRowMarginPercent / 100;

        options.stroke = options.stroke || '#FFFFFF';

        options.legendTextSize = options.legendTextSize || 11;

        values = preCalcValues();

        if (options.manualPositions) {
            setManualPositions();
        } else {
            autoCalcPositions(values);
        }

        // sectorPercentLabelMargin: 0 or undefined value for inside label
        if (options.sectorPercentLabelMargin) {
            percentLabelPosition =  r + options.sectorPercentLabelMargin;
        } else {
            percentLabelPosition = r2 + (r - r2) /2;
        }

        return values;
    }

    function drawPieChart(values) {
        if (values && values.length) {
            for (var i = 0; i < values.length; i++) {
                createSector(values[i]);
            }
        } else if (options.showNoDataLabel) {
            chart.push(paper.text(cx, cy, options.noDataLabel || 'No data available').attr(
                {
                    'font-size': getRealSize(15),
                    'font-family': baseFontFamily
                }));

            options.stroke = '#ccc';
            noData = true;
        }

        if (options.showEmptyCircle || (values && values.length)) {
            chart.push(paper.circle(cx, cy, r).attr({stroke: options.stroke, 'stroke-width': options.strokeWidth || 1}));
        }
    }

    function drawLegend(values) {
        if (values && values.length) {
            for (var i = 0; i < values.length; i++) {
                addLegend(values[i]);
            }
        }
    }

    function drawDonut() {
        if (!noData && (options.donutRatio || options.holePercent)) {
            chart.push(paper.circle(cx, cy, 1).attr({fill: '#FFFFFF',
                stroke: options.stroke, 'stroke-width': 1}).animate({r: r2}, 150));
        }

        if (options.showError) {
            if (options.connectionErrorLabel) {
                var errorText = paper.text(cx, cy,
                        options.connectionErrorLabel).attr(
                    {
                        fill: '#7d7b7b',
                        'font-size': getRealSize(totalLabelSize),
                        'font-family': baseFontFamily
                    });

                chart.push(errorText);
            }
        } else if (options.showTotal) {
            // Te factor used on the operation is adapted to correct the dismatch between real size and height
            var posY = cy + getRealSize(totalLabelSize) / 1.8;
            var total = paper.text(cx, posY,
                M2M.lib.Format.genericNumber(options.total, 0, true)).attr(
                    {
                        fill: '#000000',
                        'font-size': getRealSize(totalNumSize),
                        'font-family': baseFontFamily,
                        'cursor': options.total && options.totalClickable ? 'pointer' : 'default'
                    });

            if (options.total && options.totalClickable){
                total.click(function() {
                    onClick(null, options.total, null, options.totalLiteral);
                });
            }

            chart.push(total);

            if (options.totalLiteral) {
                var text = paper.text(cx, posY - total.getBBox().height / 2 - 5,
                        options.totalLiteral).attr(
                    {
                        fill: '#7d7b7b',
                        'font-size': getRealSize(totalLabelSize),
                        'font-family': baseFontFamily,
                        'cursor': options.total && options.totalClickable ? 'pointer' : 'default'
                    });

                if (options.total && options.totalClickable){
                    text.click(function() {
                        onClick(null, options.total, null, options.totalLiteral);
                    });
                }

                chart.push(text);
            }
        }
    }

    function drawTitle() {
        if (options.title) {
            chart.push(paper.text(cx, titleBelow ? height - titleHeight : titleHeight / 2,
                options.title).attr({'font-size': getRealSize(15), 'font-family': baseFontFamily,
                    'font-weight': 'bold'}));
        }
    }

    function drawLegendColumnsTitle() {
        if (options.keyName) {
            var curXPos = legendXPos;

            legendYPos += legendTitleHeight / 2;
            curXPos += legendValueMaxWidth + legendLabelMargin;
            var keyText = paper.text(curXPos, legendYPos, options.keyName).attr({fill: options.legendTitleColor,
                'font-size': getRealSize(legendTitleSize), 'font-family': baseFontFamily, 'text-anchor': 'end'});

            chart.push(keyText);

            curXPos += legendPercentMaxWidth + legendLabelMargin;
            var percentTxt = paper.text(curXPos,
                    legendYPos, '%').attr({fill: options.legendTitleColor, 'font-size': getRealSize(legendTitleSize),
                    'font-family': baseFontFamily, 'text-anchor': 'end'});

            chart.push(percentTxt);

            if (options.valueName) {
                curXPos += legendLabelMargin + legendIconSize + legendIconMargin;
                if (!options.legendSquare) {
                    curXPos += legendIconSize;
                }
                var valueTxt = paper.text(curXPos,
                        legendYPos, options.valueName).attr({fill: options.legendTitleColor,
                        'font-size': getRealSize(legendTitleSize), 'font-family': baseFontFamily,
                        'text-anchor': 'start'});

                chart.push(valueTxt);
            }

            legendYPos += legendTitleHeight + 2 * legendRowMargin;
        }
    }

    function drawSeparator() {
        var sepX = 0;
        var sepY = 0;
        var sepXd = 0;
        var sepYd = 0;

        switch (options.legendPosition) {
            case 'right':
                    sepXd = sepX =  width - legendWidth;
                    sepY = 0;
                    sepYd = height - piechartYMargin;
                 break;
            case 'left':
                    sepX = sepXd = legendWidth;
                    sepY = 0;
                    sepYd = height - piechartYMargin;
                break;
            case 'top':
                    sepX = piechartXMargin;
                    sepXd = width - piechartXMargin;
                    sepYd = sepY = height - pieHeight - piechartYMargin / 2;
                break;
            case 'bottom':
                    sepX = piechartXMargin;
                    sepXd = width - piechartXMargin;
                    sepYd = sepY = pieHeight + piechartYMargin / 2;
                break;
        }

        paper.path([
            'M', sepX, sepY,
            'L', sepXd, sepYd]).attr({ 'stroke': '#a3a2a2', 'stroke-opacity': 0.5 });
    }

    function init() {
        var values = setUp();
        createTooltip();

        Raphael.getColor.reset();

        drawPieChart(values);
        drawDonut();
        drawTitle();

        if (options.legend && values && values.length > 0) {
            if (options.showLegendSeparator) {
                drawSeparator();
            }

            drawLegendColumnsTitle();
            drawLegend(values);
        }
    }

    /* PUBLIC METHODS */
    this.toggleSector = function(p) {
        if (!p.selected) {
            p.stop().animate({transform: 's' + sectorIncrement + ' ' + sectorIncrement +
                ' ' + cx + ' ' + cy}, animationMs, animationEasing);
            if (p.legendIcon) {
                if (options.legendSquare) {
                    var newSize = legendIconSize * 2 + summaryIconIncrement;
                    var newX = p.legendIcon.attrs.x - summaryIconIncrement / 2;
                    var newY = p.legendIcon.attrs.y - summaryIconIncrement / 2;
                    p.legendIcon.animate({ x: newX, y: newY, width: newSize, height: newSize }, animationMs, animationEasing);
                } else {
                    p.legendIcon.animate({ r: legendIconSize + (legendIconSize / 2) }, animationMs, animationEasing);
                }

                if (summaryIconBorder) {
                    p.legendIcon.attr({stroke: '#555', 'stroke-width': summaryIconBorder});
                }
            }
            p.selected = true;
        } else {
            p.stop().animate({transform: ''}, animationMs, 'easeOut');
            if (p.legendIcon) {
                if (options.legendSquare) {
                    var newSize = legendIconSize * 2;
                    var newX = p.legendIcon.attrs.x + summaryIconIncrement / 2;
                    var newY = p.legendIcon.attrs.y + summaryIconIncrement / 2;
                    p.legendIcon.animate({ x: newX, y: newY, width: newSize, height: newSize }, animationMs, animationEasing);
                } else {
                    p.legendIcon.animate({ r: legendIconSize }, animationMs, animationEasing);
                }

                if (summaryIconBorder) {
                    p.legendIcon.attr({stroke: 'none'});
                }
            }
            p.selected = false;
        }
    };

    init();

    return this;
};


